import React from 'react'

var Gazua = () => {
    return (
        <div>
            {/* Cats Dogs */}
            <h1 style={{color: 'black'}}>Cats Dogs</h1>
            <a href="https://t.me/catsdogs_game_bot/join?startapp=6026855291">
                https://t.me/catsdogs_game_bot/join?startapp=6026855291
            </a>
            {/* W-cCin */}
            <h1 style={{color: 'purple'}}>W-Coin</h1>
            <a href="https://t.me/wcoin_tapbot/wcoin_app?startapp=NjAyNjg1NTI5MQ==">
                https://t.me/wcoin_tapbot/wcoin_app?startapp=NjAyNjg1NTI5MQ==
            </a>
            {/* Tabizoo */}
            <h1 style={{color: 'skyblue'}}>Tabizoo</h1>
            <a href="https://t.me/tabizoobot/tabizoo?startapp=6026855291">
                https://t.me/tabizoobot/tabizoo?startapp=6026855291
            </a>
            {/* Boinkers */}
            <h1 style={{color: 'purple'}}>Boinkers</h1>
            <a href="https://t.me/boinker_bot/boinkapp?startapp=boink6026855291">
                https://t.me/boinker_bot/boinkapp?startapp=boink6026855291
            </a>
            {/* Tomarket */}
            <h1 style={{color: 'red'}}>Tomarket</h1>
            <a href="https://t.me/Tomarket_ai_bot/app?startapp=0001o55U">
                https://t.me/Tomarket_ai_bot/app?startapp=0001o55U
            </a>

            {/* Digmon Channel */}
            {/* <h1>DigMon Channel</h1>
            <a href="https://t.me/DigmonUp">
                https://t.me/DigmonUp
            </a> */}
            {/* Blum Squad */}
            {/* <h1 style={{color: 'black'}}>Blum Squad</h1>
            <a href="https://t.me/BlumCryptoBot/app?startapp=tribe_blow_win-ref_AkE8HRx1Lg">
                https://t.me/BlumCryptoBot/app?startapp=tribe_blow_win-ref_AkE8HRx1Lg
            </a> */}
            {/* Do not Enter */}
            {/* <h1 style={{color: 'red'}}>Do Not Enter</h1>
            <a href="https://t.me/+PG_7Ej8-Hg43NmY1">
                https://t.me/+PG_7Ej8-Hg43NmY1
            </a> */}
            {/* Hamster Kombat */}
            {/* <h1 style={{color: 'orange'}}>Hamster Kombat</h1>
            <h3>Referral</h3>
            <a href='https://t.me/hamster_kombat_bot/start?startapp=kentId6026855291'>
                https://t.me/hamster_kombat_bot/start?startapp=kentId6026855291
            </a> */}
        </div>
    );
};

export default Gazua;